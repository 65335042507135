import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { EventType, Router, RouterLink, RouterLinkActive } from "@angular/router";
import { AuthService } from "../../core/services/auth.service";
import { filter, interval } from "rxjs";
import { permissionType } from "src/app/models/enums";
import { UserIdleService } from "wspowerpack";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import { NgIf } from "@angular/common";
import { JobSelectorModalComponent } from "../job-selector-modal/job-selector-modal.component";
import { TimesheetClient } from "src/app/api/client";
import { ProductionTrackerSelectorModalComponent } from "../production-tracker-selector-modal/production-tracker-selector-modal.component";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  standalone: true,
  imports: [RouterLink, RouterLinkActive, NgIf],
})
export class NavbarComponent implements OnInit {
  @ViewChild("timeout", { static: true }) timeoutDialog!: TemplateRef<any>;
  showMobileMenu: boolean = false;
  dialogRef: NgbModalRef | null = null;
  count: number = 30;
  allowUsers: boolean = false;
  isA: boolean = false;
  welcome: string | null = null;
  isAdmin: boolean = false;
  isLoading: boolean = true;
  apiRoot: string = environment.apiRoot;
  isAdminRoute: boolean = false;
  constructor(
    private userIdle: UserIdleService,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private timesheetClient: TimesheetClient
  ) {
    this.isAdmin = this.authService.hasPermission(permissionType.Administrator);
  }

  ngOnInit(): void {
    this.isA = this.authService.isLoggedIn();
    this.welcome = this.authService.getUserDisplayName();

    this.authService.isLoggedIn$.subscribe((l) => {
      this.welcome = this.authService.getUserDisplayName();
      if (l) {
        this.setIdle();
        this.addActiveClassToAdminDropdown();
      } else {
        this.stop();
        this.stopWatching();
      }
    });

    this.authService.renew(); //renew on first refresh and then every 10 minutes
    interval(600000).subscribe(() => {
      console.log("Renew");
      this.authService.renew();
    });

    this.router.events.pipe(filter((x) => x.type == EventType.NavigationEnd)).subscribe((data) => {
      if (this.authService.isLoggedIn()) {
        this.addActiveClassToAdminDropdown();
      }
    });

    // setTimeout(() => console.log(this.authService.getCurrentContext()?.context?.user) , 2000);
  }

  downloadLaborReport(): void {
    this.dialogRef = this.modalService.open(JobSelectorModalComponent, {
      backdrop: "static",
    });

    this.dialogRef.componentInstance.submitCompletedEvent.subscribe((jobId) => {
      this.timesheetClient.generateCostReport(jobId).subscribe(
        (data) => {
          const byteCharacters = atob(data.fileBase64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          let thefile = new Blob([byteArray], { type: data.contentType });
          //let url = window.URL.createObjectURL(thefile);
          //window.open(url);
          const a = document.createElement("a");
          document.body.appendChild(a);
          const url = window.URL.createObjectURL(thefile);
          a.href = url;
          a.download = data.fileName;
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
          }, 0);

          //let file = new File([byteArray], data.fileName, { type: data.contentType });
          //let exportUrl = URL.createObjectURL(file);
          //window.location.assign(exportUrl);
          //URL.revokeObjectURL(exportUrl);
        }, //console.log(data),
        (error) => console.log(error),
        () => console.log("Completed file download.")
      );
    });
  }

  downloadProductionTracker(): void {
    this.dialogRef = this.modalService.open(ProductionTrackerSelectorModalComponent, {
      backdrop: "static",
    });

    this.dialogRef.componentInstance.submitCompletedEvent.subscribe((selectorForm) => {
      this.timesheetClient
        .generateProductionTracker(
          selectorForm.controls["year"].value,
          selectorForm.controls["startMonth"].value,
          selectorForm.controls["endMonth"].value
        )
        .subscribe(
          (data) => {
            const byteCharacters = atob(data.fileBase64);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            let thefile = new Blob([byteArray], { type: data.contentType });
            //let url = window.URL.createObjectURL(thefile);
            //window.open(url);
            const a = document.createElement("a");
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(thefile);
            a.href = url;
            a.download = data.fileName;
            a.click();
            setTimeout(() => {
              window.URL.revokeObjectURL(url);
              document.body.removeChild(a);
            }, 0);

            //let file = new File([byteArray], data.fileName, { type: data.contentType });
            //let exportUrl = URL.createObjectURL(file);
            //window.location.assign(exportUrl);
            //URL.revokeObjectURL(exportUrl);
          }, //console.log(data),
          (error) => console.log(error),
          () => console.log("Completed file download.")
        );
    });
  }

  onSignout(): void {
    this.authService.logout();
  }

  onShowMobileMenu(): void {
    this.showMobileMenu = !this.showMobileMenu;
  }

  //*********** IDLE TIMEOUT //***********
  setIdle(): any {
    console.log("Setup Idle");
    //Start watching for user inactivity.
    this.userIdle.startWatching();
    this.userIdle.resetTimer();
    //Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count) => {
      this.count = 30 - count;
      if (this.dialogRef == null && count != null && count < 30) {
        this.dialogRef = this.modalService.open(this.timeoutDialog, {
          backdrop: "static",
        });
      }
      if (count == 30) {
        if (this.dialogRef != null) this.dialogRef.close("");
      }
    });

    //Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      console.log("Time is up!");
      if (this.dialogRef != null) this.dialogRef.close("");
      this.authService.logout();
      this.stop();
      this.stopWatching();
      this.dialogRef = null;
    });

    //Ping
    this.userIdle.ping$.subscribe(() => {
      console.log("Idle! " + new Date().toLocaleTimeString());
      if (this.authService.isLoggedIn()) {
        this.authService.renew();
      }
    });
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  onContinue() {
    this.dialogRef?.close("");
    this.restart();
    this.startWatching();
    this.setIdle();
    this.dialogRef = null;
  }

  onManualLogout() {
    this.dialogRef?.close();
    this.authService.logout();
    this.stop();
    this.stopWatching();
    this.dialogRef = null;
  }

  // helper function to determine if the active class should be added to the administration dropdown link
  addActiveClassToAdminDropdown(): void {
    let url = window.location.pathname;
    if (url.includes("admin") && !url.includes("admin/jobs")) {
      this.isAdminRoute = true;
    } else {
      this.isAdminRoute = false;
    }
  }
}

import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule, FormLabelModule } from 'wspowerpack';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormRepoService } from '../services/form-repo.service';
import { JobClient, JobV1Model } from 'src/app/api/client';

@Component({
  selector: 'app-job-selector-modal',
  standalone: true,
  imports: [CommonModule, ModalModule, ReactiveFormsModule, FormLabelModule],
  templateUrl: './job-selector-modal.component.html',
  styleUrls: ['./job-selector-modal.component.scss']
})
export class JobSelectorModalComponent {
  @Output() submitCompletedEvent = new EventEmitter();
  
  jobForm = this.formRepoService.selectJobForm();
  jobs: JobV1Model[] = [];
  constructor(
    public activeModal: NgbActiveModal,
    private formRepoService: FormRepoService,
    private jobClient: JobClient
  ) {}

  ngOnInit(): void {
    this.jobClient.getActive().subscribe((data) => {
      this.jobs = data;
    });
  }

  onConfirm(): void {
    if (this.jobForm.invalid) {
      return;
    }

    this.submitCompletedEvent.emit(this.jobForm.controls['jobId'].value);
    this.activeModal.close();
  }

  onCancel(): void {
    this.activeModal.close();
  }
}

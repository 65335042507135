import { Injectable } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

@Injectable({
  providedIn: "root",
})
// Using this as a shared service for getting copies of various forms. Might help not having to recreate the form every time we need one on a different page.
// Might be able to define like a "Base" form model for each model and then make modifications to it on the functions to return like an edit version or a new version.
export class FormRepoService {
  constructor(private formBuilder: FormBuilder) {}

  //quick mock of the new account form
  newAccountForm(): FormGroup {
    return this.formBuilder.group({
      name: ["", [Validators.required]],
      ownerName: ["", [Validators.required]],
      accountTypeId: ["", [Validators.required]],
      accountNumber: ["", [Validators.required]],
      country: ["US", [Validators.required]],
      internationalAddress: [""],
      address: ["", [Validators.required]],
      city: ["", [Validators.required]],
      state: ["", [Validators.required]],
      zip: ["", [Validators.required]],
      accountPhone: ["", [Validators.required]],
      accountEmail: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
      parentAccountId: [0],
    });
  }

  newWorkOrderForm(): FormGroup {
    return this.formBuilder.group({
      workOrder: ["", [Validators.required]],
      requestedDate: ["", [Validators.required]],
      requestedTime: ["", [Validators.required]],
      technicianUserId: [""],
      procedureRevisionId: [null, [Validators.required]],
    });
  }

  manageAccountLicensesForm(): FormGroup {
    return this.formBuilder.group({
      licensesScoping: [0, [Validators.required]],
      licensesWriting: [0, [Validators.required]],
      licensesExecution: [0, [Validators.required]],
      licensesScheduling: [0, [Validators.required]],
      licensesAnalytics: [0, [Validators.required]],
    });
  }

  newDelayCodeForm(): FormGroup {
    return this.formBuilder.group({
      delayCode: ["", [Validators.required]],
      delayName: ["", [Validators.required]],
      description: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newPartsForm(): FormGroup {
    return this.formBuilder.group({
      name: ["", [Validators.required]],
      shortName: ["", [Validators.required]],
      idNumber: ["", [Validators.required]],
      uom: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newToolForm(): FormGroup {
    return this.formBuilder.group({
      name: ["", [Validators.required]],
      shortName: ["", [Validators.required]],
      toolNumber: [null, [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newHoldCodeForm(): FormGroup {
    return this.formBuilder.group({
      holdName: ["", [Validators.required]],
      approvedByGroupId: ["", [Validators.required]],
      releaseCode: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newLaborForm(): FormGroup {
    return this.formBuilder.group({
      position: ["", [Validators.required]],
      shortName: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
      icon: ["None"],
    });
  }

  newLaborToStep(): FormGroup {
    return this.formBuilder.group({
      quantity: ["1", [Validators.required]],
    });
  }

  newHazardForm(): FormGroup {
    return this.formBuilder.group({
      description: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newPrecautionForm(): FormGroup {
    return this.formBuilder.group({
      description: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newProcedureForm(): FormGroup {
    return this.formBuilder.group({
      name: ["", [Validators.required]],
      procedureNumber: [null, [Validators.required]],
      scopeDocumentId: [""],
      levelOfUse: ["Continuous", [Validators.required]],
    });
  }

  newScopingForm(): FormGroup {
    return this.formBuilder.group({
      scopeName: ["", [Validators.required]],
      description: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newProcedureRevisionForm(): FormGroup {
    return this.formBuilder.group({
      purpose: [""],
      scope: [""],
      isDraft: [true],
    });
  }

  newNotesForm(): FormGroup {
    return this.formBuilder.group({
      note: [""],
    });
  }

  editRevisionForm(): FormGroup {
    return this.formBuilder.group({
      levelOfUse: ["", Validators.required],
      departmentOwner: ["", Validators.required],
    });
  }

  newProcedurePublishForm(): FormGroup {
    return this.formBuilder.group({
      effectiveDate: ["", [Validators.required]],
    });
  }

  newEquipmentForm(): FormGroup {
    return this.formBuilder.group({
      name: ["", [Validators.required]],
      shortName: ["", [Validators.required]],
      idNumber: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }
  editSkillLevelForm(): FormGroup {
    return this.formBuilder.group({
      skillLevel: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }
  newSkillLevelForm(): FormGroup {
    return this.formBuilder.group({
      skillLevel: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newMaterialCategoryForm(): FormGroup {
    return this.formBuilder.group({
      category: ["", [Validators.required]],
    });
  }

  editMaterialCategoryForm(): FormGroup {
    return this.formBuilder.group({
      category: ["", [Validators.required]],
    });
  }

  newSubcontractorCategoryForm(): FormGroup {
    return this.formBuilder.group({
      category: ["", [Validators.required]],
    });
  }

  editSubcontractorCategoryForm(): FormGroup {
    return this.formBuilder.group({
      category: ["", [Validators.required]],
    });
  }

  editCostCodeForm(): FormGroup {
    return this.formBuilder.group({
      taskName: ["", [Validators.required]],
      costCode: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }
  newCostCodeForm(): FormGroup {
    return this.formBuilder.group({
      taskName: ["", [Validators.required]],
      costCode: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newSkillLevelCostForm(): FormGroup {
    return this.formBuilder.group({
      cost: ["", [Validators.required]],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  editSkillLevelCostForm(): FormGroup {
    return this.formBuilder.group({
      cost: ["", [Validators.required]],
      startDate: ["", [Validators.required]],
      endDate: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  editEmployeeForm(): FormGroup {
    return this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      employeeNumber: [null, [Validators.required]],
      skillLevelId: [null, [Validators.required]],
      unionCode: [null, [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }
  newEmployeeForm(): FormGroup {
    return this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      employeeNumber: [null, [Validators.required]],
      skillLevelId: [null, [Validators.required]],
      unionCode: [null, [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  editJobForm(): FormGroup {
    return this.formBuilder.group({
      jobNumber: ["", [Validators.required]],
      jobDescription: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newJobForm(): FormGroup {
    return this.formBuilder.group({
      jobNumber: ["", [Validators.required]],
      jobDescription: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  selectJobForm(): FormGroup {
    return this.formBuilder.group({
      jobId: ["", [Validators.required]],
    });
  }

  selectProductTrackerForm(): FormGroup {
    return this.formBuilder.group({
      year: [
        new Date().getFullYear(),
        [
          Validators.required,
          Validators.min(2024),
          Validators.max(2050),
          Validators.pattern("^[0-9]*$"),
        ],
      ],
      startMonth: [
        1,
        [
          Validators.required,
          Validators.min(1),
          Validators.max(12),
          Validators.pattern("^[0-9]*$"),
        ],
      ],
      endMonth: [
        3,
        [
          Validators.required,
          Validators.min(1),
          Validators.max(12),
          Validators.pattern("^[0-9]*$"),
        ],
      ],
    });
  }

  editTimesheetForm(): FormGroup {
    return this.formBuilder.group({
      jobId: ["", [Validators.required]],
      employeeIds: ["", [Validators.required]],
      costCodeId: ["", [Validators.required]],
    });
  }

  newTimesheetForm(): FormGroup {
    return this.formBuilder.group({
      jobId: ["", [Validators.required]],
      employeeId: ["", [Validators.required]],
      costCodeId: ["", [Validators.required]],
      sundayStandardHours: [null, [Validators.min(0)]],
      sundayOvertimeHours: [null, [Validators.min(0)]],
      sundayWeldInches: [null, [Validators.min(0)]],
      mondayStandardHours: [null, [Validators.min(0)]],
      mondayOvertimeHours: [null, [Validators.min(0)]],
      mondayWeldInches: [null, [Validators.min(0)]],
      tuesdayStandardHours: [null, [Validators.min(0)]],
      tuesdayOvertimeHours: [null, [Validators.min(0)]],
      tuesdayWeldInches: [null, [Validators.min(0)]],
      wednesdayStandardHours: [null, [Validators.min(0)]],
      wednesdayOvertimeHours: [null, [Validators.min(0)]],
      wednesdayWeldInches: [null, [Validators.min(0)]],
      thursdayStandardHours: [null, [Validators.min(0)]],
      thursdayOvertimeHours: [null, [Validators.min(0)]],
      thursdayWeldInches: [null, [Validators.min(0)]],
      fridayStandardHours: [null, [Validators.min(0)]],
      fridayOvertimeHours: [null, [Validators.min(0)]],
      fridayWeldInches: [null, [Validators.min(0)]],
      saturdayStandardHours: [null, [Validators.min(0)]],
      saturdayOvertimeHours: [null, [Validators.min(0)]],
      saturdayWeldInches: [null, [Validators.min(0)]],
    });
  }

  newJobMaterialForm(): FormGroup {
    return this.formBuilder.group({
      description: ["", [Validators.required]],
      amount: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
      jobMaterialCategoryId: ["", [Validators.required]],
    });
  }

  editJobMaterialForm(): FormGroup {
    return this.formBuilder.group({
      description: ["", [Validators.required]],
      amount: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
      jobMaterialCategoryId: ["", [Validators.required]],
    });
  }

  newJobMaterialBudgetForm(): FormGroup {
    return this.formBuilder.group({
      jobMaterialCategoryId: ["", [Validators.required]],
      description: ["", [Validators.required]],
      amount: ["", [Validators.required]],
      requestedDate: ["", [Validators.required]],
    });
  }

  editJobMaterialBudgetForm(): FormGroup {
    return this.formBuilder.group({
      jobMaterialCategoryId: ["", [Validators.required]],
      description: ["", [Validators.required]],
      amount: ["", [Validators.required]],
      requestedDate: ["", [Validators.required]],
    });
  }

  newJobSubcontractorBudgetForm(): FormGroup {
    return this.formBuilder.group({
      jobSubcontractorCategoryId: ["", [Validators.required]],
      amount: ["", [Validators.required]],
      requestedDate: ["", [Validators.required]],
    });
  }

  editJobSubcontractorBudgetForm(): FormGroup {
    return this.formBuilder.group({
      jobSubcontractorCategoryId: ["", [Validators.required]],
      amount: ["", [Validators.required]],
      requestedDate: ["", [Validators.required]],
    });
  }

  editJobLaborForm(): FormGroup {
    return this.formBuilder.group({
      employeeId: ["", [Validators.required]],
      standardHours: ["", [Validators.required, Validators.min(0)]],
      overtimeHours: ["", [Validators.required, Validators.min(0)]],
      weldInches: ["", [Validators.required, Validators.min(0)]],
      dateWorked: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newJobLaborBudgetForm(): FormGroup {
    return this.formBuilder.group({
      costCodeId: ["", [Validators.required]],
      totalCost: ["", [Validators.required]],
      totalHours: ["", [Validators.required, Validators.min(0)]],
      requestedDate: ["", [Validators.required]],
    });
  }

  editJobLaborBudgetForm(): FormGroup {
    return this.formBuilder.group({
      costCodeId: ["", [Validators.required]],
      totalCost: ["", [Validators.required]],
      totalHours: ["", [Validators.required, Validators.min(0)]],
      requestedDate: ["", [Validators.required]],
    });
  }

  changeTimesheetWeekForm(): FormGroup {
    return this.formBuilder.group({
      selectedDate: ["", [Validators.required]],
    });
  }

  newJobSubcontractorForm(): FormGroup {
    return this.formBuilder.group({
      contractorName: ["", [Validators.required]],
      description: ["", [Validators.required]],
      amount: ["", [Validators.required]],
      jobSubcontractorCategoryId: ["", [Validators.required]],
    });
  }

  editJobSubcontractorForm(): FormGroup {
    return this.formBuilder.group({
      contractorName: ["", [Validators.required]],
      description: ["", [Validators.required]],
      amount: ["", [Validators.required]],
      jobSubcontractorCategoryId: ["", [Validators.required]],
    });
  }

  newUsersForm(): FormGroup {
    return this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      jobTitle: [""],
      address: [""],
      city: [""],
      state: [""],
      zip: [""],
      phone: [""],
      email: ["", [Validators.required]],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&]).*$/),
        ],
      ],
      isActive: [true, [Validators.required]],
      confirmPassword: ["", [Validators.required]],
      roleId: [""],
    });
  }

  editUsersForm(): FormGroup {
    return this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      jobTitle: [""],
      address: [""],
      city: [""],
      state: [""],
      zip: [""],
      phone: [""],
      email: ["", [Validators.required]],
      isActive: [true, [Validators.required]],
      roleId: [""],
    });
  }
}

export const environment = {
  production: true,

  apiRoot: "https://key-fab-api.codingwestmoreland.com", // Base URL for the api
  stsAuthority: "https://key-fab-api.codingwestmoreland.com", // Base URL for the token service
  clientId: "KF_SecureAccount", // Client ID of this application on the token server
  clientRoot: "https://key-fab.codingwestmoreland.com", // Base URL of the client application
  clientSecret: "088c7f72-6473-4bd2-b11d-6435d565d3c0",
  logErrorsToAPI: true,
  requireExportPassword: true,
  version: "1.0.0.1",
  idle: 1740,
};

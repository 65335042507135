import { Component } from "@angular/core";
import { AuthService } from "./modules/core/services/auth.service";
import { Router, RouterOutlet } from "@angular/router";
import { NavbarComponent } from "./modules/shared/navbar/navbar.component";
import { NgIf } from "@angular/common";
import { WSSyncFusionGridConfig, WSSyncFusionGridModule } from "wspowerpack";
import { environment } from "../environments/environment";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        NavbarComponent,
        RouterOutlet
    ],
})
export class AppComponent {
  isA: boolean = false;
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.isA = this.authService.isLoggedIn();
    this.authService.isLoggedIn$.subscribe((l) => {
      this.isA = l;
    });
  }
}

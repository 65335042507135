import { Injectable } from '@angular/core';
import { ToastrService, IndividualConfig } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  config: Partial<IndividualConfig>; // only used for enabling HTML in toastr alerts presently...

  constructor(private toast: ToastrService) {
    this.config = this.toast.toastrConfig;
  }

  info(message: string) {
    this.toast.info(message);
  }

  success(message: string) {
    this.toast.success(message);
  }

  successHtml(message: string) {
    this.config.enableHtml = true;
    this.toast.success(message, undefined, this.config);
  }

  error(message: string) {
    this.toast.error(message);
  }
  errorHTML(message: string) {
    this.config.enableHtml = true;
    this.toast.error(message, undefined, this.config);
  }

  warning(message: string) {
    this.toast.warning(message);
  }

  warningHTML(message: string) {
    this.config.enableHtml = true;
    this.toast.warning(message, undefined, this.config);
  }

  alert(message: string) {
    this.toast.info(message, undefined, {
      tapToDismiss: true,
      closeButton: true,
      disableTimeOut: true,
    });
  }

  alertHTML(message: string) {
    this.config.enableHtml = true;
    this.toast.info(message, undefined, {
      tapToDismiss: false,
      closeButton: true,
      disableTimeOut: true,
    });
  }
}

import { Routes } from "@angular/router";
import { NotFoundComponent } from "./modules/shared/not-found/not-found.component";
import { UnauthorizedComponent } from "./modules/shared/unauthorized/unauthorized.component";
import { AuthorizationGuard } from "./modules/core/guards/authorization.guard";
import { permissionType } from "./models/enums";
import { LoginComponent } from "./modules/login/login.component";

export const APP_ROUTES: Routes = [
  {
    path: "home",
    canActivate: [AuthorizationGuard],
    data: {
      auth: [permissionType.Authenticated],
    },
    loadChildren: () =>
      import("./modules/timesheet/timesheet.routes").then((m) => m.TIMESHEET_ROUTES),
  },
  {
    path: "admin",
    canActivate: [AuthorizationGuard],
    data: {
      auth: [permissionType.Authenticated],
    },
    loadChildren: () => import("./modules/admin/admin.routes").then((m) => m.ADMIN_ROUTES),
  },
  { path: "login", component: LoginComponent },
  { path: "unauthorized", component: UnauthorizedComponent },
  { path: "notfound", component: NotFoundComponent },
  { path: "**", redirectTo: "/notfound" },
];

export enum permissionType {
  Authenticated = 1,
  Administrator = 2,
}

export enum UserSettingsBaseEnum {
  user_list = "user.list",
  master_vehicle_list = "master.vehicle.list",
}

export enum WhereToUpload {}

import { Injectable } from "@angular/core";
import { FormGroup, ValidationErrors, FormControl } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { FormFieldAliasModel } from "../../../models/form-field-alias";
import { ValidationErrorModel } from "src/app/models/validation-error";

// This service provides data to validation display components.  It merges reactive forms client side validation and server side validation
//  into a single array, and makes the data available via observable.  It should be provided directly on the component, so that multiple instances
//  of the service could exist in the case of multiple active componets with forms.  All child components of the component on which it is provided
//  will have access to its observable.

@Injectable()
export class FormValidationService {
  private errorList: ValidationErrorModel[] = []; // Stores the errors returned from the server
  private form: FormGroup | null = null; // The form on the component
  private fieldAliasList: FormFieldAliasModel[] = []; // List of aliases for form control names.  Used to translate from the model field name to human readable description.

  public errorSubject = new BehaviorSubject<ValidationErrorModel[]>(
    this.errorList
  );
  public errorSubject$ = this.errorSubject.asObservable(); // Subscribed to by validation display components

  // Called in onInit of the instantiating component.
  setupForm(
    formFromComponent: FormGroup | null,
    fieldAliasArray?: FormFieldAliasModel[],
    showErrors: boolean = true
  ) {
    this.form = formFromComponent;

    // An array of field aliases can optionally be passed in by the component.  This allows for mapping the form errors to 'clean' field names.
    if (fieldAliasArray != null) {
      this.fieldAliasList = fieldAliasArray;
    }

    // On reactive form field value cahnge - remove any server side validation messages
    Object.keys(this.form!.controls).forEach((key) => {
      this.form!.get(key)!.valueChanges.subscribe((value) => {
        this.removeValidationErrorByField(key);
      });
    });

    // When client side reactive form validation status changes - update the observable
    this.form!.statusChanges.subscribe((result) => {
      this.sendUpdatedErrorList();
    });

    // Trigger initial validation
    //this.form.updateValueAndValidity();
  }

  fieldHasRequiredValidator(fieldName: string) {
    let formControl = new FormControl();
    if (this.form != null) {
      if (this.form.controls[fieldName] != null) {
        if (
          this.form.controls[fieldName].validator &&
          (this.form.controls[fieldName].value == null ||
            this.form.controls[fieldName].value == "")
        ) {
          let validationResult =
            this.form.controls[fieldName].validator!(formControl);
          return (
            validationResult !== null && validationResult["required"] === true
          );
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  sendUpdatedErrorList() {
    // Include server side errors
    let ssErrors = Object.assign([], this.errorList); // this.errorList;

    // Get client side validation errors from the form - for individual fields
    Object.keys(this.form!.controls).forEach((key) => {
      const controlErrors: ValidationErrors | null =
        this.form!.get(key)!.errors || null;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          // Use field alias if available
          let fieldNameToDisplay: string = key;
          if (
            this.fieldAliasList.filter((x) => x.formFieldName == key).length > 0
          ) {
            fieldNameToDisplay = this.fieldAliasList.filter(
              (x) => x.formFieldName == key
            )[0].formFieldDisplayName;
          }

          // Build error object
          let clError: ValidationErrorModel = new ValidationErrorModel();
          clError.field = key;
          clError.errorType = keyError;
          if (this.form!.get(key)!.value != null)
            clError.error =
              "Please enter a valid value for " + fieldNameToDisplay;
          ssErrors.push(clError);
        });
      }
    });

    // Get client side validation errors from the form - for general form - not individual fields
    if (this.form!.errors != null) {
      Object.keys(this.form!.errors).forEach((keyError) => {
        // Build error object
        let clError: ValidationErrorModel = new ValidationErrorModel();
        clError.field = "form";
        clError.errorType = keyError;

        // ToDo - split this out into a separate mapping function
        if (keyError == "passwordsDoNotMatch") {
          clError.error = "The password fields do not match.";
        } else {
          clError.error = "General form error";
        }

        ssErrors.push(clError);
      });
    }

    // Push this new error list to the observable
    this.errorSubject.next(ssErrors);
  }

  removeValidationErrorByField(fieldName: string) {
    for (let i = this.errorList.length - 1; i >= 0; --i) {
      if (this.errorList[i].field == fieldName) {
        this.errorList.splice(i, 1);
      }
    }
    this.sendUpdatedErrorList();
  }

  addValidationErrorsFrom400(error: any) {
    if (error.status == 400) {
      // Get the error data from the ProblemDetails object
      const data = error.error.errors;
      console.log(error.error.errors);
      const fields = Object.keys(error.error.errors || {});

      // Iterate each error from the ProblemDetails object
      fields.forEach((field) => {
        data[field].forEach((e: any) => {
          if (
            this.errorList.filter((x) => x.field == field && x.error == e)
              .length < 1
          ) {
            // Create error object and add to the array
            let newError: ValidationErrorModel = new ValidationErrorModel();
            newError.field = field;
            newError.error = e;
            this.errorList.push(newError);
          }
        });
      });

      this.sendUpdatedErrorList();
    }
  }

  clearValidationErrors() {
    this.errorList = [];
    this.sendUpdatedErrorList();
  }
}

import { registerLicense } from "@syncfusion/ej2-base";
import { environment } from "./environments/environment";
import { enableProdMode, APP_INITIALIZER, importProvidersFrom } from "@angular/core";
import { AppComponent } from "./app/app.component";
import { provideToastr, ToastrModule } from "ngx-toastr";
import { provideAnimations } from "@angular/platform-browser/animations";
import { bootstrapApplication } from "@angular/platform-browser";
import { API_BASE_URL } from "./app/api/client";
import { AuthService } from "./app/modules/core/services/auth.service";
import { Http500Interceptor } from "./app/modules/core/interceptors/Http500.interceptor";
import { AuthInterceptor } from "./app/modules/core/interceptors/auth.interceptor";
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from "@angular/common/http";
import { provideRouter } from "@angular/router";
import { APP_ROUTES } from "./app/app.routes";
import {
  UserIdleConfig,
  WSSyncFusionGridComponent,
  WSSyncFusionGridConfig,
  WSSyncFusionGridModule,
} from "wspowerpack";

const GRID_CONFIG: WSSyncFusionGridConfig = {
  apiRoot: environment.apiRoot + "/",
};

const userIdleConfig: UserIdleConfig = {
  idle: environment.idle,
  timeout: 300,
  ping: 120,
};

// import { UserIdleConfig } from "wspowerpack/lib/wspowerpack/user-idle-module/user-idle-config";
const appInitializerFn = (appConfig: AuthService) => {
  return () => {
    return appConfig.initializeApp();
  };
};

if (environment.production) {
  enableProdMode();
}
bootstrapApplication(AppComponent, {
  providers: [
    { provide: API_BASE_URL, useValue: environment.apiRoot },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Http500Interceptor,
      multi: true,
    },
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: UserIdleConfig,
      useValue: userIdleConfig,
    },
    {
      provide: WSSyncFusionGridConfig,
      useValue: GRID_CONFIG,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(APP_ROUTES),
    provideToastr(),
    provideAnimations(),
    // importProvidersFrom(BrowserAnimationsModule),
  ],
}).catch((err) => console.error(err));
// Registering Syncfusion license key
registerLicense("ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5QdkJiXHpYdH1XQ2RV");

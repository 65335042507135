import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JobV1Model, JobClient } from 'src/app/api/client';
import { ModalModule, FormLabelModule } from 'wspowerpack';
import { FormRepoService } from '../services/form-repo.service';

@Component({
  selector: 'app-production-tracker-selector-modal',
  standalone: true,
  imports: [CommonModule, ModalModule, ReactiveFormsModule, FormLabelModule],
  templateUrl: './production-tracker-selector-modal.component.html',
  styleUrls: ['./production-tracker-selector-modal.component.scss']
})
export class ProductionTrackerSelectorModalComponent {
  @Output() submitCompletedEvent = new EventEmitter();
  
  selectorForm = this.formRepoService.selectProductTrackerForm();
  constructor(
    public activeModal: NgbActiveModal,
    private formRepoService: FormRepoService,
  ) {}

  ngOnInit(): void {
  }

  onConfirm(): void {
    if (this.selectorForm.invalid) {
      return;
    }



    this.submitCompletedEvent.emit(this.selectorForm);
    this.activeModal.close();
  }

  onCancel(): void {
    this.activeModal.close();
  }
}
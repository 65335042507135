import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators, FormBuilder, ReactiveFormsModule } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastService } from "../../core/services/toast.service";
import { FormValidationService } from "../validation/form-validation.service";
import { PasswordClient, ResetPasswordV1Model, UserClient } from "../../../api/client";
import { NgIf, NgFor } from "@angular/common";

@Component({
    selector: "app-reset-password",
    templateUrl: "./reset-password.component.html",
    styleUrls: ["./reset-password.component.scss"],
    providers: [FormValidationService],
    standalone: true,
    imports: [
        NgIf,
        ReactiveFormsModule,
        NgFor,
    ],
})
export class ResetPasswordComponent implements OnInit {
  email: string | null = null;
  reset_error: string = "";
  TimeForCode: boolean = false;
  loginForm = new FormGroup({
    loginUsername: new FormControl("", [Validators.required, Validators.email]),
  });
  resetForm = new FormGroup({
    code: new FormControl(null, Validators.required),
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&]).*$/),
    ]),
    confirmPassword: new FormControl(null, Validators.required),
  });
  passwordErrors: any = {
    required: "Password is required.",
    minlength: "Password needs at least 8 characters long.",
    digits: "Password needs at least one digit.",
    uppercase: "Password needs at least one uppercase letter.",
    lowercase: "Password needs at least one lowercase letter.",
    special: "Password needs at least one special character.",
    match: "Passwords need to match.",
  };
  isPasswordValid: boolean = true;
  isConfirmValid: boolean = true;
  isPasswordVisible: boolean = false;
  isConfirmVisible: boolean = false;
  isLoading: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private toastService: ToastService,
    private userClient: UserClient,
    private passwordClient: PasswordClient,
  ) {}

  ngOnInit() {
    if (this.email != null) {
      this.loginForm.controls.loginUsername.setValue(this.email);
    }
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      this.toastService.error("Ensure that fields are filled out properly");
      return;
    }
    this.passwordClient
      .beginResetPassword(this.loginForm.controls["loginUsername"].value!)
      .subscribe((result) => {
        this.TimeForCode = true;
      });
  }

  onSubmitReset() {
    let model: ResetPasswordV1Model = new ResetPasswordV1Model();
    model.code = this.resetForm.controls["code"].value;
    model.password = this.resetForm.controls["password"].value;
    model.email = this.loginForm.controls["loginUsername"].value;
    this.passwordClient.resetPassword(model).subscribe((result) => {
      if (result.totalRowsUpdated == 0) {
        this.reset_error = "Unable to reset password";
      } else {
        this.activeModal.close();
        this.toastService.success(
          "Your password has been reset.  Please login with your new credentials."
        );
      }
    });
  }

  getPasswordErrors(): string[] {
    const passwordControl = this.resetForm.get("password");
    const errors = passwordControl?.errors;

    if (!passwordControl || !errors) {
      return [];
    }

    const errorMessages: string[] = [];

    if (errors["required"]) {
      errorMessages.push(this.passwordErrors.required);
    }

    if (errors["minlength"]) {
      errorMessages.push(this.passwordErrors.minlength);
    }

    if (errors["pattern"]) {
      if (!/(?=.*\d)/.test(passwordControl.value!)) {
        errorMessages.push(this.passwordErrors.digits);
      }
      if (!/(?=.*[a-z])/.test(passwordControl.value!)) {
        errorMessages.push(this.passwordErrors.lowercase);
      }
      if (!/(?=.*[A-Z])/.test(passwordControl.value!)) {
        errorMessages.push(this.passwordErrors.uppercase);
      }
      if (!/(?=.*[$@$!%*?&])/.test(passwordControl.value!)) {
        errorMessages.push(this.passwordErrors.special);
      }
    }

    return errorMessages;
  }

  getMatchingError(): string[] {
    const passwordControl = this.resetForm.get("password");
    const confirmControl = this.resetForm.get("confirmPassword");

    const errorMessages: string[] = [];

    if (passwordControl != confirmControl) {
      errorMessages.push(this.passwordErrors.match);
    }

    return errorMessages;
  }

  onPasswordChange(): void {
    this.isPasswordValid = this.resetForm.get("password")?.valid ?? true;
  }

  onConfirmChange(): void {
    if (
      this.resetForm.get("confirmPassword")?.value ==
      this.resetForm.get("password")?.value
    ) {
      this.isConfirmValid = true;
    } else {
      this.isConfirmValid = false;
    }
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleConfirmVisibility(): void {
    this.isConfirmVisible = !this.isConfirmVisible;
  }

  onCancel() {
    this.TimeForCode = false;
  }
}
